import { Box, Card, CardMedia, IconButton, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import Inputfield from '../ui/Inputfield'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import './lock.component.css'

function Lockcards({item, index}) {
  const [displaylock,setdisplaylock] = useState({
    0 : false,
    1 : false,
    2 : false
  })
  const handelview = (index)=>{
    setdisplaylock((prev)=>{
      return {
        ...prev,
        [index] : !prev[index]
      }
    })
  }

  return (
    <>
    
    <Card className='lock_card_history' >
       <Box sx={{display:"flex",alignItems:"center"}}>
     
      
       <div style={{display:"flex",flexDirection:"column"}}>
  <p style={{margin:"1rem"}} className='lock_card_date' >{new Date(item?.created_date).toLocaleString()}</p>
           
            <div style={{display:"flex"}}>
          
            <Inputfield
                            label={
                              "Lock"
                            }
                            type={displaylock[index] ? "text" : "password"}
                           
                            labelprops={{ shrink: true }}
                            value={item?.current_lock}
                            inputprops={{
                              readOnly : true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={()=>handelview(index)}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    {
                                      displaylock[index] ? <VisibilityOff /> :  <Visibility />
                                    }
                                    
                                  </IconButton>
                                </InputAdornment>
                              ),
                            
                            }}
                           
                            size="small"
                          />
            </div>

        </div>
      
       </Box>
      </Card>
    
    </>
  )
}

export default Lockcards
