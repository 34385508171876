import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../bagdetails/bagdetails.component.css";
// import Logo from "../../assets/tag8logoblack.png";
// import Bagimage from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import Card from "@material-ui/core/Card";
import { TextField, Grid, Typography } from "@material-ui/core";
import { FcCellPhone } from "react-icons/fc";
import { FaRupeeSign } from "react-icons/fa";
import "./bagposter.component.css";
import axios from "axios";
import html2canvas from "html2canvas";
// import * as FaIcons from "react-icons/fa";
// import * as FcIcons from "react-icons/fc";
import { switchUrls } from "../../api/index.js";
import * as GiIcons from "react-icons/gi";
// import luggage from "../../assets/Luggage.png";
// import Bagpack from "../../assets/backpacks.png";
// import laptopbag from "../../assets/LaptopBag.png";
// import briefcase from "../../assets/Briefcase.png";
// import camerabag from "../../assets/camerabag.png";
// import GymBag from "../../assets/Gymbag.png";
// import TrolleyBag from "../../assets/trolleybag.png";
// import Suitcase from "../../assets/suitcase.png";
// import LadiesPurse from "../../assets/Ladiespurse.png";
// import SportsKitBag from "../../assets/sportskitbag.png";
// import DuffelBag from "../../assets/duffelbag.png";
// import SchoolBag from "../../assets/schoolbag.png";
// import otherbag from "../../assets/other.png";

const baseURL = switchUrls("bag");

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default class BagPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      description: "",
      bag_color: "",
      bag_brand: "",
      identification_mark: "",
      owner_phone: "",
      owner_email: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      bag_imagelocation: "",
      // valuableImages: {
      //   Luggage: "https://storage.googleapis.com/pettag/qr/assets/luggage.png",
      //   Bagpacks:
      //     "https://storage.googleapis.com/pettag/qr/assets/backpack.png",
      //   LaptopBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/laptopbag.png",
      //   Briefcase:
      //     "https://storage.googleapis.com/pettag/qr/assets/briefcase.png",
      //   CameraBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/camerabag.png",
      //   GymBag: "https://storage.googleapis.com/pettag/qr/assets/GymBag.png",
      //   TrolleyBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/trolley-bag.png",
      //   Suitcase:
      //     "https://storage.googleapis.com/pettag/qr/assets/suitcase.png",
      //   LadiesPurse:
      //     "https://storage.googleapis.com/pettag/qr/assets/ladiespurse.png",
      //   SportsKitBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png",
      //   DuffelBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/duffelbag.png",
      //   SchoolBag:
      //     "https://storage.googleapis.com/pettag/qr/assets/schoolbag.png",
      //   OtherBags: "https://storage.googleapis.com/pettag/qr/assets/other.png",
      // },
      valuableTypeToIdMap: {
        "Laptop Bag": 1,
        Briefcase: 2,
        "Camera Bag": 3,
        "Gym Bag": 4,
        "Trolley bag": 5,
        Suitcase: 6,
        "Ladies Purse": 7,
        "Sports Kit Bag": 8,
        "Hand Bag/Duffel Bag": 9,
        "Other Bags": 10,
        "School Bag": 11,
        Luggage: 12,
      },
      valuableImages: {
        1: "laptopbag.png",
        2: "briefcase.png",
        3: "camerabag.png",
        4: "GymBag.png",
        5: "trolley-bag.png",
        6: "suitcase.png",
        7: "ladiespurse.png",
        8: "sport-bag.png",
        9: "duffelbag.png",
        10: "other.png",
        11: "schoolbag.png",
        12: "luggage.png",
      },
      active: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/bag/getreportlocation/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get(
        `${baseURL}/api/bag/getBagImagePoster/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          bag_imagelocation: response.data.bag_imagelocation,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get(
        `${baseURL}/api/bag/getReportLost/` + this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          valuable_type: response.data.valuable_type,
          description: response.data.description,
          bag_color: response.data.bag_color,
          bag_brand: response.data.bag_brand,
          identification_mark: response.data.identification_mark,
          owner_phone: response.data.owner_phone,
          owner_email: response.data.owner_email,
          other_noofbags: response.data.other_noofbags,
          lost_text: response.data.lost_text,
          lost_date: response.data.lost_date,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          lost_timeone: response.data.lost_timeone,
          lost_timetwo: response.data.lost_timetwo,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });

        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  downloadposter() {
    let elem = document.getElementById("contentToConvert");
    html2canvas(elem, { useCORS: true, allowTaint: true }).then(function (
      canvas
    ) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `bag-poster.png`;
      a.click();
    });
  }

  toggleMenu() {
    console.log(this.state.active);

    this.setState({
      active: !this.state.active,
    });
  }
  convertCamelCaseToWords = (str) => {
    if (!str) return "";
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
      .replace(
        /\b(\w+\b)/g,
        (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
      ); // Capitalize each word
  };
  showOptions() {
    if (this.state.showdiv) {
      document.getElementById("bdiv").style.bottom = "-14.5rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";

      this.setState({ showdiv: false });
    } else {
      document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";

      this.setState({ showdiv: true });
    }
  }

  render() {
    const {
      owner_firstname,
      owner_lastname,
      bag_color,
      bag_brand,
      valuable_type,
      description,
      lost_text,
      owner_phone,
      reward,
      rewardcurrency,
      bag_imagelocation,
      valuableImages,
      valuableTypeToIdMap,
      active,
    } = this.state;
    const ownerName = `${owner_firstname} ${owner_lastname}`;
    const ownerDetails = owner_phone
      ? `${ownerName} - ${owner_phone}`
      : ownerName;
    // const bagImageLocation = valuableTypeToImage[valuable_type];
    const selectedId = valuableTypeToIdMap[valuable_type];
    // console.log(selectedId, "ID");
    const selectedImageFilename = valuableImages[selectedId];
    const bagImageLocation = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(bagImageLocation, "bga");
    const formattedValuableType = valuable_type
      ? this.convertCamelCaseToWords(valuable_type)
      : "";

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <div className="genie-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/tag8logoblack.png"}
            alt="logo"
            style={{ width: "5rem" }}
          />

          <button
            className="bag-poster-toggle-btn"
            onClick={() => this.toggleMenu()}
          >
            <GiIcons.GiHamburgerMenu />
          </button>
        </div>
        <div>
          <div
            className={
              active ? "poster-utility" : "poster-utility poster-utility-hide"
            }
          >
            {/* <button className="poster-utility-btns" onClick={this.downloadposter}>
            Download
          </button> */}

            <a
              className="poster-utility-btns"
              href={
                "/#/bag/edit-report-lost/" + this.props.match.params.tag_number
              }
            >
              Edit
            </a>

            <a
              onClick={() => {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
              className="poster-utility-btns"
            >
              Go To My Tags
            </a>
          </div>
          <div className="bagposter-container">
            <div id="contentToConvert" className="pb-3">
              <Card
                className=" key-mat-card"
                style={{
                  background:
                    "linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c)",
                }}
              >
                <div
                  className="lost-info-detail-header background"
                  style={{ textAlign: "center" }}
                >
                  <p>
                    {formattedValuableType ? (
                      <p className="header-font-key header-text-key ">
                        LOST {formattedValuableType}!
                      </p>
                    ) : null}
                  </p>
                </div>
                {/* <p className="header-font-key  lost-data-header"></p> */}

                <div className="custom-container">
                  <div>
                    <img
                      src={
                        bagImageLocation
                          ? bagImageLocation
                          : "https://storage.googleapis.com/pettag/qr/bagpack.png"
                      }
                      alt="valuable"
                      // className="mat-card-image2"
                    />
                  </div>
                  <div className="col-md-2 colorw">
                    {lost_text ? (
                      <div className="poster-info-wrapper">
                        <TextField
                          variant="outlined"
                          label="Lost Message"
                          fullWidth
                          value={toTitleCase(lost_text)}
                          readOnly={true}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <Grid container className="poster-msg-wrapper-cover">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="poster-msg-wrapper"
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      className="poster-msg"
                    >
                      <p>PLEASE HELP ME FIND MY</p>
                    </Typography>
                    {/* <p>BAG</p> */}

                    <TextField
                      label="Make Call"
                      variant="outlined"
                      value={`${ownerDetails} `}
                      fullWidth
                      className="poster-owner-phone"
                      InputProps={{
                        readOnly: true,
                        startAdornment: <FcCellPhone />,
                      }}
                    />
                  </Grid>

                  {rewardcurrency && reward ? (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ background: "white" }}
                    >
                      <TextField
                        label="Reward"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          startAdornment:
                            rewardcurrency === "Rupees" ? (
                              <FaRupeeSign />
                            ) : null,
                        }}
                        value={reward}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Card>
            </div>
          </div>
        </div>
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
