import React, { useEffect, useState } from "react";
import Navbar from "../../header/header.component";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import "../../bagdetails/bagdetails.component.css";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import MuiPhoneInput from "material-ui-phone-number";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Country, State } from "country-state-city";
import HelpIcon from "@mui/icons-material/Help";
import { Link } from "react-router-dom";
import Footer from '../../mainfooter/footerblack.jsx'

import { APP_ENV, switchUrls } from "../../../api";
const gateway = switchUrls("gateway");
const bagurl = switchUrls("bag");
const gatewayFrontend = switchUrls("gateway_frontend")

function Claimform() {
  const { tag_number } = useParams();
  const [showEr,setshowEr] = useState(false)
  const [sidebar, setsidebar] = useState(true);
  const [submenu, setsubmenu] = useState(false);
  const [defaultRegion, setDefaultRegion] = useState("");
  const [userdetails, setuserdetails] = useState({});
  const [formdata, setformdata] = useState({
    arrival_flight: "",
    airline: "",
    airline_confnumber: "",
    flight_arrival_date: "",
    file_reference_number: "",
    no_of_undeliveredbags: 1,
    tag_number: tag_number || "",
    first_name: "",
    last_name: "",
    contactno: "",
    country_code: "",
    email: "",
    lost_text: "",
    payment_first_name: "",
    payment_last_name: "",
    payment_address: "",
    payment_city: "",
    payment_country: "",
    payment_state: "",
    payment_zip: "",
    payment_email: "",
    no_of_contactperson: 1,
    person_firstname: "",
    person_lastname: "",
    person_email: "",
    person_contact: "",
    person1_country_code: "",
    person2_firstname: "",
    person2_lastname: "",
    person2_email: "",
    person2_contact: "",
    person2_country_code: "",
    reportlost_type: "inside_airport",
    payment_type: "bank",
    upi_id: "",
    paypal_firstname: "",
    paypal_lastname: "",
    paypal_email: "",
    payment_bank: "",
    payment_account: "",
    swift_code: "",
    type: "insert",
  });
  const [snackBar, setsnackBar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
  });
  const [loader,setloader] = useState(false)

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }
  const capitilizeFirstword = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };
  const getcountryIsocode = () => {
    let countryDetails = Country.getAllCountries().filter((e) => {
      return e.name === capitilizeFirstword(formdata.payment_country);
    });
    return countryDetails[0]?.isoCode;
  };

  const fetchClaimdetails = async () => {
    try {
      setloader(true)
      const { data } = await axios.get(`${bagurl}/api/getclaim/${tag_number}`);
      if (data?.status) {
        if (data?.claimDetails) {
          
          const {  
            arrival_flight,
            airline,
            airline_confnumber,
            flight_arrival_date,
            file_reference_number,
            no_of_undeliveredbags,
            tag_number,
            first_name,
            last_name,
            contactno,
            country_code,
            email,
            lost_text,
            payment_first_name,
            payment_last_name,
            payment_address,
            payment_city,
            payment_country,
            payment_state,
            payment_zip,
            payment_email,
            no_of_contactperson,
            person_firstname,
            person_lastname,
            person_email,
            person_contact,
            person1_country_code,
            person2_firstname,
            person2_lastname,
            person2_email,
            person2_contact,
            person2_country_code,
            reportlost_type,
            payment_type,
            upi_id,
            paypal_firstname,
            paypal_lastname,
            paypal_email,
            payment_bank,
            payment_account,
            swift_code,
           
  } = data?.claimDetails
          setformdata((prev)=>{
            return {
              ...prev,
              airline,
              airline_confnumber,
              flight_arrival_date,
              file_reference_number,
              no_of_undeliveredbags,
              tag_number,
              first_name,
              last_name,
              contactno,
              country_code,
              email,
              lost_text,
              payment_first_name,
              payment_last_name,
              payment_address,
              payment_city,
              payment_country,
              payment_state,
              payment_zip,
              payment_email,
              no_of_contactperson,
              person_firstname,
              person_lastname,
              person_email,
              person_contact,
              person1_country_code,
              person2_firstname,
              person2_lastname,
              person2_email,
              person2_contact,
              person2_country_code,
              reportlost_type,
              payment_type :  payment_type?.length ?  payment_type : "bank",
              upi_id,
              paypal_firstname,
              paypal_lastname,
              paypal_email,
              payment_bank,
              payment_account,
              swift_code,
              arrival_flight
            }
          })
          //setshowEr(true);
        }
        else{
          setshowEr(true)
        }
      }
    } catch (er) {}
  };

  const fetchUserdetails = async () => {
    try {
      const { data } = await axios.get(`${bagurl}/api/getloggedinuser`, {
        headers: { Authorization: readCookie("token") },
      });
      setuserdetails(data?.userDetails);
      const { email, phone, first_name, last_name, phonecountrycode } =
        data?.userDetails;
      setformdata((prev) => {
        return {
          ...prev,
          first_name,
          last_name,
          email,
          contactno: phone,
          country_code: phonecountrycode,
          person1_country_code: phonecountrycode,
          person_contact: phone,
          person_email: email,
          person_firstname: first_name,
          person_lastname: last_name,
        };
      });
      setloader(false)
    } catch (error) {
      setloader(false)
      //return
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();

        if (data && data.country_code) {
          const userCountryCode = data.country_code.toLowerCase();

          setDefaultRegion(userCountryCode);
        } else {
          // Handle case when country code is not available
          // console.log("Country code not found in API response");
          // Set a default region or handle the situation accordingly
          // setDefaultRegion("us");
        }
      } catch (error) {
        // console.log("Error fetching user country:", error);
        // setDefaultRegion("us");
      }
    };

    fetchUserCountry();
    fetchUserdetails();
    fetchClaimdetails();
  }, []);

  const handelsubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(formdata);
      const { data } = await axios.post(`${bagurl}/api/updateclaim`, formdata);
      if (data?.status) {
        setsnackBar((prev) => {
          return {
            ...prev,
            openSnackbar: true,
            snackbarMessage: "Claim Updated Successfully",
          };
        });
        // Delay the redirection to ensure Snackbar is displayed
        setTimeout(() => {
          if(APP_ENV === "local"){

            window.location.href = gatewayFrontend 

          }
          else{

              // Redirect to the dashboard after a delay
          window.location.href = "/dashboard/" + localStorage.getItem("uid");

          }
        
        }, 1000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
      } else {
        setsnackBar((prev) => {
          return {
            ...prev,
            openSnackbar: true,
            snackbarMessage: data?.message || "Failed Please Try Again",
          };
        });
      }
    } catch (error) {
      console.log(error?.response?.data);
      setsnackBar((prev) => {
        return {
          ...prev,
          openSnackbar: true,
          snackbarMessage:
            error?.response?.data?.message || "Failed Please Try Again",
        };
      });
    }
  };
  const handelchange = (e) => {
    setformdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const handlecontactphone = (value, details) => {
    let { dialCode } = details;
    if (dialCode && value) {
      const phCode = "+" + dialCode;

      const phoneNumber = value.replace(phCode, "");

      setformdata((prev) => {
        return {
          ...prev,
          country_code: phCode,
          contactno: phoneNumber,
        };
      });
    }
  };
  const handlepersonphone = (value, details) => {
    let { dialCode } = details;
    if (dialCode && value) {
      const phCode = "+" + dialCode;

      const phoneNumber = value.replace(phCode, "");

      setformdata((prev) => {
        return {
          ...prev,
          person1_country_code: phCode,
          person_contact: phoneNumber,
        };
      });
    }
  };
  const handleperson2phone = (value, details) => {
    let { dialCode } = details;
    if (dialCode && value) {
      const phCode = "+" + dialCode;

      const phoneNumber = value.replace(phCode, "");

      setformdata((prev) => {
        return {
          ...prev,
          person2_country_code: phCode,
          person2_contact: phoneNumber,
        };
      });
    }
  };
  const handelradiobutton = (e) => {
    setformdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <Navbar />
      <div
        className={
          showEr
            ? "editPetDetails-main-wrapper-shower"
            : "editPetDetails-main-wrapper"
        }
      >
        <div className="keydetails-left-wrapper">
          <div className="keydetails-sidebar-toggle-btn-wrapper">
            <button
              className="keydetails-sidebar-toggle-btn"
              onClick={() => {
                setsidebar(!sidebar);
              }}
            >
              {sidebar ? (
                <BsIcons.BsLayoutSidebarInset />
              ) : (
                <BsIcons.BsLayoutSidebarInsetReverse />
              )}
            </button>
          </div>

          <div
            className={
              sidebar
                ? "keydetails-sidebar-container-hide"
                : "keydetails-sidebar-container"
            }
          >
            <button
              className="keydetails-sidebar-menu-link"
              onClick={() => {
                setsubmenu(!submenu);
              }}
            >
              MY TAGS{" "}
              {submenu ? (
                <AiIcons.AiFillCaretDown />
              ) : (
                <AiIcons.AiFillCaretUp />
              )}
            </button>

            <ul
              className={
                submenu
                  ? "keydetails-sidebar-menu-ul-hide"
                  : "keydetails-sidebar-menu-ul"
              }
            >
              <li>
                <button
                  className="keydetails-sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = gateway + "/dashboard/" + tag_number;
                  }}
                >
                  All Tags
                </button>
              </li>

              <li>
                <button
                  className="keydetails-sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = gateway + "/dashboard/" + tag_number;
                  }}
                >
                  Active | Inactive Tags
                </button>
              </li>

              <li>
                <button
                  className="keydetails-sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = gateway + "/dashboard/" + tag_number;
                  }}
                >
                  Lost | Found Tags
                </button>
              </li>
            </ul>

            <button
              className="keydetails-sidebar-menu-link"
              onClick={() => {
                window.location.href = gateway + "/dashboard/" + tag_number;
              }}
            >
              ACTIVATE TAG
            </button>

            <button
              className="keydetails-sidebar-menu-link"
              onClick={() => {
                window.location.href =
                  gateway + "/scan-notification/" + tag_number;
              }}
            >
              NOTIFICATIONS
            </button>
          </div>
        </div>

        <div className="editPetDetails-right-wrapper">
          {loader ? (
           <div className="loader-container">
           <div className="loader">
             <img
               src={
                 "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
               }
               alt="Loading..."
               loading="lazy"
               style={{ width: "100px", height: "100px" }}
             />
           </div>
         </div>
          ) : (
            <>
            {
              showEr ? <>
               <div className="form_div" style={{display:"flex",marginTop:"2rem"}}>
      <span className="step_heading">
            Claim Details not found.Do you wish to report a claim then   <span style={{ color: "blue" }}>
                  <Link
                    style={{ color: "blue" }}
                    to={`/bag/report-lost/${tag_number}`}
                  >
                    Click Here
                  </Link>
                </span><span style={{color:"blue"}}>
           
              </span>
          </span>
          </div>
              </> : <>
                <form onSubmit={handelsubmit}>
                <div>
                  <div>
                    <h4 style={{ textAlign: "center" }}> Edit Report Lost</h4>
                  </div>
                  <div className="form_div">
                    <div className="step_div">
                      <span className="step_heading">
                        STEP 1: Report Lost Type
                      </span>
                    </div>
                    <br></br>
                    <span className="claim_remarks">
                      PLEASE CHOOSE WHERE YOU LOST THE BAG
                    </span>

                    <div>
                      <FormControl>
                        <RadioGroup
                          onChange={handelradiobutton}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          defaultValue="female"
                          name="reportlost_type"
                          value={formdata.reportlost_type}
                        >
                          <FormControlLabel
                            value="inside_airport"
                            control={<Radio />}
                            label="Inside Airport"
                          />
                          <FormControlLabel
                            value="outside_airport"
                            control={<Radio />}
                            label="Outside Airport"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  {formdata.reportlost_type === "inside_airport" && (
                    <>
                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 2: Customer Details
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                             InputLabelProps={{
                              shrink: true,
                              style: { color: "#666" },
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="First Name"
                              variant="outlined"
                              name="first_name"
                              value={formdata.first_name}
                            
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                             InputLabelProps={{
                              shrink: true,
                              style: { color: "#666" },
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Last Name"
                              variant="outlined"
                              name="last_name"
                              value={formdata.last_name}
                           
                            />
                          </div>
                        </div>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1.5em",
                              }}
                            >
                              {defaultRegion && (
                                <MuiPhoneInput
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: "#666" },
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                  required
                                  defaultCountry={defaultRegion}
                                  regions={[
                                    "america",
                                    "caribbean",
                                    "asia",
                                    "middle-east",
                                    "oceania",
                                    "europe",
                                    "africa",
                                  ]}
                                  name="contactno"
                                  value={
                                    formdata?.country_code + formdata.contactno
                                  }
                                  variant="outlined"
                                  onChange={handlecontactphone}
                                />
                              )}
                            </div>
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                             InputLabelProps={{
                              shrink: true,
                              style: { color: "#666" },
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Email"
                              variant="outlined"
                              name="email"
                              value={formdata.email}
                              onChange={handelchange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 3: AIRLINE INFORMATION
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <span className="claim_remarks">
                          PLEASE COMPLETE THE FIELDS EXACTLY AS THEY APPEAR ON
                          YOUR CLAIM WITH THE AIRLINE.
                        </span>
                        <div
                          style={{ marginTop: "1rem" }}
                          className="two-field-wrapper"
                        >
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Arrival Flight"
                              variant="outlined"
                              name="arrival_flight"
                              value={formdata.arrival_flight}
                              onChange={handelchange}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Airline"
                              variant="outlined"
                              name="airline"
                              value={formdata.airline}
                              onChange={handelchange}
                            />
                          </div>
                        </div>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Airline Conf"
                              variant="outlined"
                              name="airline_confnumber"
                              value={formdata.airline_confnumber}
                              onChange={handelchange}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              required
                              type="date"
                              // className="bagactivation-input-tag"
                              variant="outlined"
                              label="Flight Arrival Date"
                              name="flight_arrival_date"
                              value={formdata.flight_arrival_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handelchange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 4: MISHANDLED BAGGAGE INFORMATION
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <span className="claim_remarks">
                          WHEN YOU FILED A LOST BAGGAGE CLAIM WITH THE AIRLINE
                          YOU RECEIVED A FILE REFERENCE NUMBER AND BAGGAGE TAG
                          NUMBER, PLEASE ENTER THEM HERE:
                        </span>

                        <div
                          style={{ marginTop: "1rem" }}
                          className="two-field-wrapper"
                        >
                          <div
                            className="editPetDetails-input-wrapper"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="File Reference"
                              variant="outlined"
                              name="file_reference_number"
                              value={formdata.file_reference_number}
                              onChange={handelchange}
                              inputProps={{ maxLength: 15, minLength: 8 }}
                            />
                            <div style={{ marginLeft: ".5rem" }}>
                              <Tooltip
                                describeChild
                                title="Your file reference number is an 8-10 digit alphanumeric code obtained from the airline."
                                leaveTouchDelay={3000}
                                enterTouchDelay={50}
                              >
                                <HelpIcon />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              required
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Tag Number"
                              variant="outlined"
                              name="tag_number"
                              value={formdata.tag_number}
                              onChange={handelchange}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Undelivered Bags
                              </InputLabel>
                              <Select
                                aria-required
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                //value={valuable_type}
                                label={"Undelivered Bags"}
                                required
                                variant="outlined"
                                name="no_of_undeliveredbags"
                                value={formdata.no_of_undeliveredbags}
                                onChange={handelchange}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 5: ADDITIONAL BAGGAGE INFORMATION
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <span className="claim_remarks">
                          PLEASE PROVIDE US WITH ANY ADDITIONAL INFORMATION YOU
                          BELIEVE IS NECESSARY FOR US TO HELP TRACK AND EXPEDITE
                          THE RETURN OF YOUR LUGGAGE.
                        </span>
                        <div style={{ textAlign: "center", marginTop: "1rem" }}>
                          <TextareaAutosize
                            rows={8}
                            className="loss-details-body text-center p-3"
                            // className="custom-textfield"
                            variant="outlined"
                            // InputProps={{ disableUnderline: true }}
                            style={{
                              fontSize: "16px",
                              borderColor: "gray",
                              // marginTop: "5rem",
                              marginLeft: "0px",
                              width: "100%",
                              padding: "8px",
                              resize: "none",
                            }}
                            name="lost_text"
                            label="Lost Text"
                            placeholder="Give a brief account of loss."
                            value={formdata.lost_text}
                            onChange={handelchange}
                          />
                        </div>
                      </div>
                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 6: PAYMENT INFORMATION
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <span className="claim_remarks">
                          NOTE: PAYMENT DETAILS CAN BE CHANGED AFTER INITIAL
                          REPORTING. THIS MAY LEAD TO A SLIGHT DELAY IN PAYMENT
                          PROCESSING.
                        </span>
                        <div style={{ marginTop: "1rem" }}>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Payment Type
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-required
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="payment_type"
                              onChange={handelradiobutton}
                              value={formdata.payment_type?.length ? formdata.payment_type : "bank"}
                            >
                              <FormControlLabel
                                value="bank"
                                control={<Radio />}
                                label="Bank"
                              />
                              <FormControlLabel
                                value="upi"
                                control={<Radio />}
                                label="UPI"
                              />
                              <FormControlLabel
                                value="paypal"
                                control={<Radio />}
                                label="PayPal"
                              />
                            </RadioGroup>
                          </FormControl>
                          {formdata.payment_type === "bank" && (
                            <>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Beneficiary First Name"
                                    variant="outlined"
                                    name="payment_first_name"
                                    value={formdata.payment_first_name}
                                    onChange={handelchange}
                                  />
                                </div>
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Beneficiary Last Name"
                                    variant="outlined"
                                    name="payment_last_name"
                                    value={formdata.payment_last_name}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Beneficiary Address"
                                    variant="outlined"
                                    name="payment_address"
                                    value={formdata.payment_address}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id="demo-simple-select-label"
                                      style={{
                                        marginTop: "-7px",
                                        marginLeft: "13px",
                                      }}
                                    >
                                      Beneficiary Country
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      label="Beneficiary Country"
                                      name="payment_country"
                                      required
                                      value={
                                        formdata.payment_country
                                          ?.charAt(0)
                                          .toUpperCase() +
                                        formdata.payment_country?.slice(1)
                                      }
                                      onChange={handelchange}
                                    >
                                      {Country?.getAllCountries()?.map((c) => {
                                        return (
                                          <MenuItem
                                            key={c.isoCode}
                                            value={c.name}
                                          >
                                            {c.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="editPetDetails-input-wrapper">
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id="demo-simple-select-label"
                                      style={{
                                        marginTop: "-7px",
                                        marginLeft: "13px",
                                      }}
                                    >
                                      Beneficiary State
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      label="Beneficiary State"
                                      name="payment_state"
                                      required
                                      value={
                                        formdata.payment_state
                                          ?.charAt(0)
                                          .toUpperCase() +
                                        formdata.payment_state?.slice(1)
                                      }
                                      onChange={handelchange}
                                      disabled={
                                        formdata.payment_country ? false : true
                                      }
                                    >
                                      {State?.getStatesOfCountry(
                                        getcountryIsocode()
                                      )?.map((c) => {
                                        return (
                                          <MenuItem
                                            key={c.isoCode}
                                            value={c.name}
                                          >
                                            {c.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Beneficiary Zip"
                                    variant="outlined"
                                    name="payment_zip"
                                    value={formdata.payment_zip}
                                    onChange={handelchange}
                                  />
                                </div>
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Beneficiary Email"
                                    variant="outlined"
                                    name="payment_email"
                                    value={formdata.payment_email}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Name Of Bank"
                                    variant="outlined"
                                    name="payment_bank"
                                    value={formdata.payment_bank}
                                    onChange={handelchange}
                                  />
                                </div>
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Account Number"
                                    variant="outlined"
                                    name="payment_account"
                                    value={formdata.payment_account}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Swift Code"
                                    variant="outlined"
                                    name="swift_code"
                                    value={formdata.swift_code}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {formdata.payment_type === "upi" && (
                            <>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="UPI ID"
                                    variant="outlined"
                                    name="upi_id"
                                    value={formdata.upi_id}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {formdata.payment_type === "paypal" && (
                            <>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Paypal Firstname"
                                    variant="outlined"
                                    name="paypal_firstname"
                                    value={formdata.paypal_firstname}
                                    onChange={handelchange}
                                  />
                                </div>

                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Paypal Lastname"
                                    variant="outlined"
                                    name="paypal_lastname"
                                    value={formdata.paypal_lastname}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                              <div className="two-field-wrapper">
                                <div className="editPetDetails-input-wrapper">
                                  <TextField
                                    fullWidth
                                    required
                                    type="text"
                                    // className="bagactivation-input-tag"
                                    label="Paypal Email Address"
                                    variant="outlined"
                                    name="paypal_email"
                                    value={formdata.paypal_email}
                                    onChange={handelchange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="form_div">
                        <div className="step_div">
                          <span className="step_heading">
                            STEP 6: ADDITIONAL CONTACT INFORMATION
                          </span>
                        </div>

                        <br></br>
                        <br></br>
                        <span className="claim_remarks">
                          PLEASE ADD ANY ADDITIONAL PERSONS YOU WISH TO HAVE
                          UPDATED REGARDING THE STATUS OF YOUR BAGGAGE.
                        </span>
                        <div
                          style={{ marginTop: "1rem" }}
                          className="two-field-wrapper"
                        >
                          <div className="editPetDetails-input-wrapper">
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  marginTop: "-7px",
                                  marginLeft: "13px",
                                }}
                              >
                                Number Of Person
                              </InputLabel>
                              <Select
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                //value={valuable_type}
                                label={"Number Of Person"}
                                required
                                value={formdata.no_of_contactperson}
                                variant="outlined"
                                name="no_of_contactperson"
                                onChange={handelchange}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              type="text"
                              // className="bagactivation-input-tag"
                              label="First Name"
                              variant="outlined"
                              name="person_firstname"
                              value={formdata.person_firstname}
                              onChange={handelchange}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Last Name"
                              variant="outlined"
                              name="person_lastname"
                              value={formdata.person_lastname}
                              onChange={handelchange}
                            />
                          </div>
                        </div>
                        <div className="two-field-wrapper">
                          <div className="editPetDetails-input-wrapper">
                            <TextField
                              fullWidth
                              type="text"
                              // className="bagactivation-input-tag"
                              label="Email Address"
                              variant="outlined"
                              name="person_email"
                              value={formdata.person_email}
                              onChange={handelchange}
                            />
                          </div>
                          <div className="editPetDetails-input-wrapper">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1.5em",
                              }}
                            >
                              {defaultRegion && (
                                <MuiPhoneInput
                                  defaultCountry={defaultRegion}
                                  regions={[
                                    "america",
                                    "caribbean",
                                    "asia",
                                    "middle-east",
                                    "oceania",
                                    "europe",
                                    "africa",
                                  ]}
                                  variant="outlined"
                                  value={
                                    formdata.person1_country_code +
                                    formdata.person_contact
                                  }
                                  onChange={handlepersonphone}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {formdata.no_of_contactperson === 2 && (
                          <>
                            <div className="two-field-wrapper">
                              <div className="editPetDetails-input-wrapper">
                                <TextField
                                  fullWidth
                                  type="text"
                                  // className="bagactivation-input-tag"
                                  label="First Name"
                                  variant="outlined"
                                  name="person2_firstname"
                                  value={formdata.person2_firstname}
                                  onChange={handelchange}
                                />
                              </div>
                              <div className="editPetDetails-input-wrapper">
                                <TextField
                                  fullWidth
                                  type="text"
                                  // className="bagactivation-input-tag"
                                  label="Last Name"
                                  variant="outlined"
                                  name="person2_lastname"
                                  value={formdata.person2_lastname}
                                  onChange={handelchange}
                                />
                              </div>
                            </div>
                            <div className="two-field-wrapper">
                              <div className="editPetDetails-input-wrapper">
                                <TextField
                                  fullWidth
                                  type="text"
                                  // className="bagactivation-input-tag"
                                  label="Email Address"
                                  variant="outlined"
                                  name="person2_email"
                                  value={formdata.person2_email}
                                  onChange={handelchange}
                                />
                              </div>
                              <div className="editPetDetails-input-wrapper">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1.5em",
                                  }}
                                >
                                  {defaultRegion && (
                                    <MuiPhoneInput
                                      defaultCountry={defaultRegion}
                                      regions={[
                                        "america",
                                        "caribbean",
                                        "asia",
                                        "middle-east",
                                        "oceania",
                                        "europe",
                                        "africa",
                                      ]}
                                      name="person2_contact"
                                      variant="outlined"
                                      value={formdata.person2_contact}
                                      onChange={handleperson2phone}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ margin: "1rem" }}>
                    <Button type="submit" variant="contained" color="success">
                      Submit
                    </Button>
                  </div>
                  <div style={{ margin: "1rem" }}>
                    <Button onClick={()=>{
                      window.location.href = gatewayFrontend
                    }} variant="outlined" color="error">
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
              </>
            }
            </>
          )}
        </div>
        <div style={{marginTop:"1rem"}} className="FooterWrapper"> <Footer /> </div>
      </div>
      <Snackbar
        open={snackBar?.openSnackbar}
        autoHideDuration={3000}
        message={snackBar?.snackbarMessage}
        action={[
          <IconButton
            key="close"
            arial-label="Close"
            color="inherit"
            onClick={() => {
              setsnackBar((prev) => {
                return {
                  ...prev,
                  openSnackbar: false,
                };
              });
            }}
          >
            X
          </IconButton>,
        ]}
      />
    </>
  );
}

export default Claimform;
