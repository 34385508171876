import React, { useEffect, useState } from 'react'
import Sidebarcontent from '../sidebar/Sidebar'
import Footer from "../mainfooter/footerblack.jsx";
import Navbar from '../header/header.component';
import Lockcards from '../lockcard/Lockcards.jsx';
import axios from 'axios';
import { switchUrls } from '../../api/index.js';
import { Box, Button, Card, CardContent, CardMedia, IconButton, } from '@mui/material';
import Inputfield from '../ui/Inputfield.jsx';
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const baseURL = switchUrls("bag");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");


function Lockmanagement() {
  const {tag_number} = useParams()
  const location1 = useLocation();
  const params = new URLSearchParams(location1?.search);
  const [lock,setlock] = useState('')
  const [showlock,setshowlock] = useState(false)
  const [lockmodal,setlockmodal] = useState( params.get('changelock') === 'true' ? true : false)
   const [loading,setloading] = useState(false)
    const[locklogs,setlocklogs] = useState([])
    const [displaylock,setdisplaylock] = useState({
      main_lock : false,
      lock_1 : false,
      lock_2 : false,
      lock_3 : false
    })
    const [currentLock,setcurrentLock] = useState('')
    const getallLogs = async ()=>{
        try {
          setloading(true)
            const {data} = await axios.get(`${baseURL}/api/getlocklogs/${tag_number}`,{
              headers : { Authorization:usertoken}
            })
            setlocklogs(data?.details)
            setcurrentLock(data?.currentlock)
            setloading(false)
            
        } catch (error) {
           setloading(false)
        }
    }
    const handelview = (field)=>{
      setdisplaylock((prev)=>{
        return {
          ...prev,
          [field] : !prev[field]
        }
      })
    }
    const handelchange = (e)=>{
      let inputValue = e.target.value;
                          
      // Replace non-numeric characters
      inputValue = inputValue.replace(/[^0-9]/g, '');
      
      
      setlock(inputValue)
      
    }
    const changelock = async (e)=>{
      e.preventDefault();
      try {
        setlockmodal(false)
        setloading(true)
        const {data} = await axios.post(`${baseURL}/api/changelock/${tag_number}`,{lock:lock},{
          headers : { Authorization:usertoken}
        })
        if(data?.status){
          getallLogs()
          setlock("")
        }
        setloading(false)
        
      } catch (error) {
       
        
      }

    }
    useEffect(()=>{
      getallLogs()
    },[])
  return (
    <>
  {
    loading ? <>
     <div className="loader-container">
                            <div className="loader">
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                                }
                                alt="Loading..."
                                loading="lazy"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </div>
    </> : <>
      <Navbar/>
      <div className='editPetDetails-main-wrapper' style={{backgroundColor:"white"}}>
      <div className="bagActivation-left-wrapper">
            <Sidebarcontent />
          </div>
          <div style={{display:"flex",flexDirection:"column",minHeight:"100vh"}}>
           <div style={{textAlign:"center",margin:"1rem",marginTop:"2rem"}}>
           <p>
                      <strong>
                       Bag Lock Details
                      </strong>
                    </p>
               
                      <div style={{textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",}}>
                      <Card sx={{ display: 'flex',alignItems:"center",justifyContent:"center" }}>
                         <CardMedia
        component="img"
        sx={{ width: 90, margin: 1 }}
        image="https://storage.googleapis.com/pettag/qr/assets/bag-qr/bag-lock1.png"
        alt="Lock_img"
      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                      <Inputfield
                      disabled={true}
  
                            label={
                              "Current Lock"
                            }
                            type={displaylock?.main_lock ? "text" : "password"}
                            //fieldname={"current_lock"}
                            value={currentLock}
                            labelprops={{ shrink: true }}
                            //onchange={this.changeHandler}
                            inputprops={{
                             
                              readOnly : true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={()=>handelview("main_lock")}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    {displaylock?.main_lock ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            
                            }}
                            helper={
                              <p style={{color:"red",fontWeight:"bold",textAlign:"right",fontSize:"14px",cursor:"pointer"}}  onClick={()=>{setlockmodal(true)}}>
                               {currentLock ? "Change Lock" : "Add New Lock"}  
                              </p>
                             
                            }
                            size="small"
                          />
                      </CardContent>
                      
                     
                      </Box>
                    
                      </Card>
                      
                    </div>
                  
                

           </div>
       {
        locklogs?.length ? <>
            <div style={{display:"flex",flexDirection:"column",marginTop:"2rem"}} >
              <p style={{textAlign:"center"}}>
              <strong>
              Last 3 Most Recent Lock Changes
                      </strong>
              </p>
            {
                locklogs?.slice().reverse().slice(0, 3)?.map((e,index)=>{
                    return (
                        <>
                         <Lockcards item={e} index={index} />
                        </>
                    )
                })
            }
          
          
           </div>
        </> :  <div style={{display:"flex",marginTop:"5rem",textAlign:"center",justifyContent:"center"}}>
                    Bag lock history are not available
                  </div>
       }
          </div>
          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
      </div>
    </>
  }
  {
    lockmodal &&      <Dialog
      fullWidth
          maxWidth="sm"
    open={lockmodal}
   

  >
    <DialogTitle>{ currentLock ?  "Change Bag Lock" : "Add New Lock"}</DialogTitle>
    <DialogContent>
    <div style={{ padding: "0px", margin: "0px" }}>
             

              <form
              onSubmit={changelock}
                className="emerg_call"
               
                
              >
             
            
                <div className="reportfoundusername">
                <Inputfield
                          required={true}
                          label={"Bag Lock"}
                          type={showlock ? "text" : "password"}
                          fieldname={"current_lock"}
                          value={lock}
                          labelprops={{ shrink: true }}
                          onchange={handelchange}
                          
                          
                          inputprops={{
                            
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                 setshowlock((prev)=>!prev)
                                  }}
                                  aria-label="toggle password visibility"
                                  edge="end"
                                >
                                  {showlock ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            inputProps: {
                              style: { boxShadow: "none", height: "2em" },
                              maxLength: 4,
                              minLength: 3, // Set the minimum length to 3
                           
                            },
                          }}
                          
                        

                        />
                </div>
                
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7px",
                  marginTop: "15px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  type='submit'
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                  }}
                  className="otp-buttons bg-warning confirm subbutton"
                 
                >
                  CONFIRM
                </Button>

                <Button
                type='button'
                onClick={()=>{
                  setlockmodal(false)
                  setlock("")
                }}
                  variant="contained"
                  className="otp-buttons cancel subbutton"
                  style={{
                    margin: "10px 20px",
                    marginBottom: "35px",
                    flex: 0.5,
                    border: "none",
                    backgroundColor:"red",
                    color:"white"
                  }}
                 
                >
                  CANCEL
                </Button>
              </div>
              </form>

            </div>
   
    </DialogContent>
 
  </Dialog>
  }
    </>
  )
}

export default Lockmanagement
