import React, { useEffect, useRef, useState } from "react";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
//import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview";
import axios from "axios";
import { APP_ENV, switchUrls } from "../../api";
import { Country } from "country-state-city";
import { QrReader } from "react-qr-reader";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { addMonths } from 'date-fns';
const baseURL = switchUrls("bag");
const gatewayFrontend = switchUrls("gateway_frontend")

function Digitalbagcard({ tag_number, tag_information, type }) {
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const [imagetype,setimagetype] = useState("img1")
  const [tagScaner, setTagscaner] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  //const [imageUrl, setimageUrl] = useState("");
  const [bagImage, setbagImage] = useState(null);
  //const [digitaltagDetails, setdigitaltagDetails] = useState([]);
  const [formdata, setformdata] = useState({
    no_of_bags: tag_information?.no_of_bags || 1,
    airLine: tag_information?.airLine || "",
    pnr: tag_information?.pnr || "",
    journey_startdate: tag_information?.journey_startdate || "",
    journey_enddate: tag_information?.journey_enddate || "",
    orgin: tag_information?.orgin || "",
    destination: tag_information?.destination || "",
    bag_brand1: tag_information?.bag_brand1 || "",
    bag_brand2: tag_information?.bag_brand2 || "",
    bag_description1: tag_information?.bag_description1 || "",
    bag_description2: tag_information?.bag_description2 || "",
    bag_image1: tag_information?.bag_image1 || "",
    bag_image2: tag_information?.bag_image2 || "",
    bag1_imageurl: tag_information?.bag1_imageurl || "",
    bag2_imageurl: tag_information?.bag2_imageurl || "",
    bag1_file: tag_information?.bag1_file || "",
    bag2_file: tag_information?.bag2_file || "",
    tag_number: tag_number,
    passenger1_firstname: tag_information?.passenger1_firstname || "",
    passenger1_lastname: tag_information?.passenger1_lastname || "",
    passenger2_firstname: "",
    passenger2_lastname: "",
    no_of_days : tag_information?.no_of_days ? tag_information?.no_of_days : 180
  });
  const [snacbarstate, setsnackbarstate] = useState({
    open: false,
    message: "",
  });
  const [succesmodal, setsuccesmodal] = useState(false);
  const [activatemodal, setactivatemodal] = useState(false);
  const [activatetagnumber, setactivatetagnumber] = useState("");
  const [maxdate,setmaxdate] = useState("")
  const[timezone,settimezone] = useState('')

  useEffect(()=>{
    settimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  },[])



  const handelactivate = () => {
    //localStorage.setItem("tag_number", "");
    //localStorage.setItem("open",true)
    setactivatemodal(true);
    setsuccesmodal(false);
    /* window.location.href =
      "http://localhost:3000/#/dashboard/" + localStorage.getItem("uid");*/
  };
  const activeredirection = () => {
    window.location.href = "#/bag-activation/" + activatetagnumber;
    window.location.reload();
  };

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  const deleteimage = (image) => {
    if(type === "details" &&  (formdata?.bag_image1 || formdata?.bag_image2)){
      axios.post(`${baseURL}/api/bag/deletebagimage`,{
        tag_number,
        image,
        digital_product : true
      },
      { headers: { Authorization: usertoken } }
    ).then(({data})=>{
        if(image === "bag1"){
          setformdata((prev) => {
            return {
              ...prev,
              bag_image1: "",
             
            };
          });
        }
        else if(image === "bag2"){
          setformdata((prev) => {
            return {
              ...prev,
              bag_image2: "",
             
            };
          });
        }
      })
    }
    if (image === "bag1") {
      if (formdata.bag1_imageurl) {
       
        setformdata((prev) => {
          return {
            ...prev,
            bag1_imageurl: "",
            bag1_file: "",
          };
        });
      }
    } else if (image === "bag2") {
      if (formdata.bag2_imageurl) {
        setformdata((prev) => {
          return {
            ...prev,
            bag2_imageurl: "",
            bag2_file: "",
          };
        });
      }
    }
  };
  let usertoken = readCookie("token");

  const [imageStates, setimageStates] = useState({
    message: "",
    uploaderror: "",
    imagemodal: false,
    selectedImage: null,
    imageUrl: null,
  });
  const [cropState, setCropstate] = useState({
    cropcenter: "",
    crop: "",
  });

  const handelChange = (e) => {
    if(e?.target?.name === "journey_startdate"){
      const startDate = new Date(e?.target?.value);
      //const maxDate = new Date(startDate);
      const sixMonthsLater = addMonths(startDate, 6);
      console.log(sixMonthsLater)
      //maxDate.setDate(maxDate.getDate() + formdata?.no_of_days);
      const maxDateFormatted = sixMonthsLater.toISOString().split('T')[0];
      console.log(maxDateFormatted)
      setmaxdate(maxDateFormatted)

      setformdata(prevState => ({
        ...prevState,
        journey_enddate: maxDateFormatted,
        [e.target.name]: e.target.value,
      }));
  
    }
    else{
      setformdata({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    }
   
 
  };
  const submitHandler = async (e) => {
  
    try {
      e.preventDefault();
      let inactive
      const fd = new FormData();
      if (type === "details") {
        const { data } = await axios.post(
          `
        ${baseURL}/api/bag/editBagDetails/${tag_number}
        `,
          formdata,
          {
            headers: { Authorization: usertoken },
          }
        );
      } else {
        setshowLoader(true)
        const { data } = await axios.post(
          `${baseURL}/api/bag/ebagactivation`,
          {formdata,timezone},
          {
            headers: { Authorization: usertoken },
          }
        );

     

        if(data?.inactive){
          
        
          inactive = true
        }
      }

      if (formdata?.bag1_file) {
        const fd = new FormData();
        fd.append("bag1", formdata?.bag1_file);
        fd.append("tag_number", tag_number);
        const { data } = await axios.post(`${baseURL}/api/bag/bag1upload`, fd, {
          headers: { Authorization: usertoken },
        });
      }
      if (formdata?.bag2_file) {
        const fd = new FormData();
        fd.append("bag2", formdata?.bag2_file);
        fd.append("tag_number", tag_number);
        const { data } = await axios.post(`${baseURL}/api/bag/bag2upload`, fd, {
          headers: { Authorization: usertoken },
        });
      }
      if (type === "details") {
        setsnackbarstate((prev) => {
          return {
            ...prev,
            open: true,
            message: "Tag Details Updated Sucessfully",
          };
        });

           // Delay the redirection to ensure Snackbar is displayed
           setTimeout(() => {
            if(APP_ENV === "local"){
              
              window.location.href = gatewayFrontend + "/#/dashboard/" ;
            }
            else{

                // Redirect to the dashboard after a delay
            window.location.href =
            "/dashboard/" + localStorage.getItem("uid");

            }
          
          }, 1000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
      

      } else {
        setsnackbarstate((prev) => {
          return {
            ...prev,
            open: true,
            message: "Tag Registered Successfully",
          };
        });
           // Delay the redirection to ensure Snackbar is displayed
           setTimeout(() => {
            if(APP_ENV === "local"){
              if(inactive){
           
                              window.location.href = gatewayFrontend + "/#/dashboard/" + localStorage.getItem("uid") + "?tab=inactive"
                            }
                            else{
                            
                              window.location.href = gatewayFrontend + "/#/dashboard/";
                            }
           

            }
            else{
               // Redirect to the dashboard after a delay
               if(inactive){
           
                window.location.href = gatewayFrontend + "/#/dashboard/" + localStorage.getItem("uid") + "?tab=inactive"
              }
              else{
              
                window.location.href = gatewayFrontend + "/#/dashboard/";
              }




            }
           
          }, 1000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
      
        //setsuccesmodal(true);
      }
    } catch (er) {
     setshowLoader(false)
      setsnackbarstate((prev) => {
        return {
          ...prev,
          open: true,
          message: er?.response?.data?.message || "Failed",
        };
      });
    }
  };

  const handelimagecrop = () => {
    setCanvasPreview(
      imageRef.current,
      canvasRef.current,
      convertToPixelCrop(
        cropState.crop,
        imageRef.current?.width,
        imageRef.current?.height
      )
    );
    canvasRef.current?.toBlob(
      (blob) => {
        let file = new File([blob], imageStates.selectedImage?.name, {
          type: "image/jpeg",
        });
        setimageStates((prev) => {
          return {
            ...prev,
            selectedImage: file,
            //imageUrl : URL.createObjectURL(blob),
            imagemodal: false,
          };
        });
        console.log(bagImage);
        if (!formdata?.bag1_imageurl?.length && imagetype === "img1") {
          //alert("if");
          setformdata((prev) => {
            return {
              ...prev,
              bag1_imageurl: URL.createObjectURL(blob),
              bag1_file: file,
            };
          });
        } else {
          //alert("else");
          console.log("reached here");
          setformdata((prev) => {
            return {
              ...prev,
              bag2_imageurl: URL.createObjectURL(blob),
              bag2_file: file,
            };
          });
        }
      },
      "image/jpeg",
      0.1
    );
  };
  const imageSize = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  const handelFilechange = async (event, type) => {
  try {
    if (event.target.files[0]) {
      setimagetype(type)
      
      setshowLoader(true)
  
          const imageDimension = await imageSize(event.target.files[0]);
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight:
              imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(
            event.target.files[0],
            options
          );
          const selectedImage = compressedFile;
          setshowLoader(false)
  
          if (selectedImage?.type) {
            if (
              selectedImage?.type === "image/jpeg" ||
              selectedImage?.type === "image/png" ||
              selectedImage?.type === "image/gif"
            ) {
              setimageStates((prev) => {
                return {
                  ...prev,
                  uploaderror: false,
                  imagemodal: true,
                  selectedImage: compressedFile,
                  imageUrl: URL.createObjectURL(compressedFile),
                };
              });
             
            } else if (
              selectedImage?.type === "image/heic" ||
              selectedImage?.type === "image/heif"
            ) {
              const convertedImageBlob = await heic2any({
                blob: compressedFile,
                toType: "image/jpeg",
                quality: 0.1, // cuts the quality and size
              });
              console.log(convertedImageBlob);
              let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
              console.log(sizeinmb);
              if (sizeinmb > 5) {
              }
              setimageStates((prev) => {
                return {
                  ...prev,
                  uploaderror: false,
                  imagemodal: true,
                  selectedImage: compressedFile,
                  imageUrl: URL.createObjectURL(compressedFile),
                };
              });
           
          
            } else {
              this.setState({
                message: "Only Png and Jpeg Images Are Acceptable",
                uploaderror: true,
                imagemodal: true,
                imagecroploading: false,
              });
            }
          } else {
            setimageStates((prev) => {
              return {
                ...prev,
                uploaderror: true,
                imagemodal: true,
                message: "Only Png and Jpeg Images Are Acceptable",
              };
            });
    
    
          }
         
         
        
      }
    
  } catch (error) {
    console.log(error)
    
  }
  };
  const cropSetup = (e) => {
    const { width, height } = e.currentTarget;
    const cropminWidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminWidth,
      },
      1,
      width,
      height
    );
    const cropcenter = centerCrop(cropAr, width, height);
    setCropstate((prev) => {
      return {
        ...prev,
        crop: cropcenter,
      };
    });
  };

  const handelcancel = () => {
    setimageStates({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  };

  return (
    <>
      <div className="editPetDetails-right-wrapper">
        <div id="breadcrumbs" className="bread_crumbs"></div>
        <div className="petDetails ">
          {
            type !== "details" && <div>
            <p style={{ color: "red" ,textAlign:"center"}}>
              Please register before your journey begins 
            </p>
          </div>
          }

          <div className="editPetDetails-form-container ">
            <div className="left-image">
              <div className="editPetDetails-user-img">
                <div>
                  {formdata?.bag1_imageurl?.length ? (
                    <>
                      <img
                        alt="imageurl"
                        src={formdata?.bag1_imageurl}
                        style={{ width: "50%", maxHeight: "256px" }}
                      />
                    </>
                  ) : formdata.bag_image1 ? (
                    <>
                      <img
                        alt="imagelocation"
                        src={formdata.bag_image1}
                        style={{ width: "50%", maxHeight: "256px" }}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt="imageyrlvector"
                        src="https://storage.googleapis.com/pettag/qr/assets/luggage.png"
                        style={{ width: "50%" }}
                      />
                    </>
                  )}
                  <div className="editPetDetails-edit-btn-wrap">
                    <label
                      htmlFor="upload1"
                      className="editPetDetails-edit-button-image"
                      style={{ cursor: "pointer" }}
                    >
                      <MdIcons.MdEdit /> Change
                    </label>
                    <input
                      onChange={(e) => handelFilechange(e, "img1")}
                      id="upload1"
                      className="editPetDetails-input-user-img"
                      type="file"
                      accept="images"
                    />

                    {formdata.bag1_imageurl.length || formdata.bag_image1 ? (
                      <>
                        <label
                          onClick={() => deleteimage("bag1")}
                          className="editPetDetails-delete-button-image "
                        >
                          <AiIcons.AiFillDelete /> Remove
                        </label>
                      </>
                    ) : null}
                  </div>
                  <span>1st Bag</span>
                </div>

                {formdata.no_of_bags === 2 && (
                  <div>
                    {formdata.bag2_imageurl.length ? (
                      <>
                        <img
                          alt="imageurl"
                          style={{ width: "50%", maxHeight: "256px" }}
                          src={formdata?.bag2_imageurl}
                        />
                      </>
                    ) : formdata.bag_image2 ? (
                      <>
                        <img
                          style={{ width: "50%", maxHeight: "256px" }}
                          alt="imagelocation"
                          src={formdata.bag_image2}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="imageyrlvector"
                          src="https://storage.googleapis.com/pettag/qr/assets/luggage.png"
                          className=""
                          style={{ width: "50%" }}
                        />
                      </>
                    )}
                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload2"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>
                      <input
                        onChange={(e) => handelFilechange(e, "img2")}
                        id="upload2"
                        className="editPetDetails-input-user-img"
                        type="file"
                        accept="images"
                      />

                      {formdata.bag2_imageurl.length || formdata.bag_image2 ? (
                        <>
                          <label
                            onClick={() => deleteimage("bag2")}
                            className="editPetDetails-delete-button-image "
                          >
                            <AiIcons.AiFillDelete /> Remove
                          </label>
                        </>
                      ) : null}
                    </div>
                    <span>2nd Bag</span>
                  </div>
                )}

                <div
                  className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                  style={{ gap: "6px" }}
                >
                  <img
                    alt="qr"
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p style={{ marginTop: "15px" }}>
                    <b>
                      {tag_number && tag_number !== "-" ? tag_number : "N/A"}
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="editPetDetails-form-container-bottom mt-3"
              style={{ width: "100%" }}
            >
              <p style={{ textAlign: "center" }}>
                {type === "details" ? (
                  <strong>Bag Details</strong>
                ) : (
                  <strong>tag8 Bag Security eTag Preactivation</strong>
                )}
              </p>

              <form onSubmit={submitHandler}>
                <div className="two-field-wrapper">
                  <div className="editPetDetails-input-wrapper">
                    {type === "details" ? (
                      <TextField
                        fullWidth
                        type="text"
                        // className="bagactivation-input-tag"
                        label="Bag Count"
                        variant="outlined"
                        name="no_of_bags"
                        value={formdata?.no_of_bags}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ marginTop: "-7px", marginLeft: "13px" }}
                        >
                          Bag Count
                        </InputLabel>
                        <Select
                          labelId="valuable-type-label"
                          id="valuable-type-select"
                          value={formdata?.no_of_bags}
                          label={"No Of Users"}
                          required
                          name="no_of_bags"
                          onChange={handelChange}
                          variant="outlined"
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </div>

                  <div className="editPetDetails-input-wrapper">
                    <TextField
                     
                      required
                      fullWidth
                      type="text"
                      onChange={handelChange}
                      // className="bagactivation-input-tag"
                      label="PNR"
                      variant="outlined"
                      name="pnr"
                      value={formdata?.pnr}
                      
                      //onChange={this.changeHandler}
                    />
                  </div>
                </div>
                <div className="two-field-wrapper">
                  <div className="editPetDetails-input-wrapper">
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginTop: "-7px", marginLeft: "13px" }}
                      >
                        Origin
                      </InputLabel>
                      <Select
                        variant="outlined"
                        label="orgin"
                        name="orgin"
                        required
                        value={
                          formdata.orgin?.charAt(0).toUpperCase() +
                          formdata.orgin?.slice(1)
                        }
                        onChange={handelChange}
                      >
                        {Country?.getAllCountries()?.map((c) => {
                          if (formdata.destination !== c.name) {
                            return (
                              <MenuItem key={c.isoCode} value={c.name}>
                                {c.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="editPetDetails-input-wrapper">
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginTop: "-7px", marginLeft: "13px" }}
                      >
                        Destination
                      </InputLabel>
                      <Select
                        variant="outlined"
                        label="Destination"
                        name="destination"
                        required
                        value={
                          formdata.destination?.charAt(0).toUpperCase() +
                          formdata.destination?.slice(1)
                        }
                        onChange={handelChange}
                      >
                        {Country?.getAllCountries()?.map((c) => {
                          if (formdata.orgin !== c.name) {
                            return (
                              <MenuItem key={c.isoCode} value={c.name}>
                                {c.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="two-field-wrapper">
                  <div className="editPetDetails-input-wrapper">
                    {type === "details" ? (
                      <TextField
                        fullWidth
                        required
                        type="date"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        label="Journey Start Date"
                        name="journey_startdate"
                        value={formdata.journey_startdate}
                        //onChange={this.changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        required
                        type="date"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        label="Journey Start Date"
                        name="journey_startdate"
                        onChange={handelChange}
                        value={formdata.journey_startdate}
                        //onChange={this.changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            min: new Date().toISOString().split('T')[0] // Set minimum selectable date to today
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="editPetDetails-input-wrapper">
                    {type === "details" ? (
                      <TextField
                        fullWidth
                        required
                        type="date"
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        label="eTag Expiry Date"
                        name="journey_enddate"
                        value={formdata.journey_enddate}
                        //onChange={this.changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        required
                        type="date"
                        disabled={formdata.journey_startdate ? false : true}
                        // className="bagactivation-input-tag"
                        variant="outlined"
                        label="eTag Expiry Date"
                        name="journey_enddate"
                        //onChange={handelChange}
                        value={formdata.journey_enddate}
                        //onChange={this.changeHandler}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      
                       
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <TextField
                      required
                      fullWidth
                      type="text"
                      // className="bagactivation-input-tag"
                      label="Airline"
                      variant="outlined"
                      name="airLine"
                      onChange={handelChange}
                      value={formdata.airLine}
                      //onChange={this.changeHandler}
                    />
                  </div>
                </div>

                <div className="two-field-wrapper">
                  <div className="editPetDetails-input-wrapper">
                    <TextField
                      required
                      fullWidth
                      type="text"
                      // className="bagactivation-input-tag"
                      label="Passenger Firstname"
                      variant="outlined"
                      name="passenger1_firstname"
                      onChange={handelChange}
                      value={formdata.passenger1_firstname}
                      //onChange={this.changeHandler}
                    />
                  </div>

                  <div className="editPetDetails-input-wrapper">
                    <TextField
                      required
                      fullWidth
                      type="text"
                      // className="bagactivation-input-tag"
                      label="Passenger Lastname"
                      variant="outlined"
                      name="passenger1_lastname"
                      onChange={handelChange}
                      value={formdata.passenger1_lastname}
                      //onChange={this.changeHandler}
                    />
                  </div>
                </div>

                <div className="two-field-wrapper">
                  <div className="editPetDetails-input-wrapper">
                    <TextField
                      fullWidth
                      type="text"
                      // className="bagactivation-input-tag"
                      label="1st Bag Brand"
                      variant="outlined"
                      name="bag_brand1"
                      onChange={handelChange}
                      value={formdata.bag_brand1}
                      //onChange={this.changeHandler}
                    />
                  </div>

                  <div className="editPetDetails-input-wrapper">
                    <TextField
                      fullWidth
                      type="text"
                      // className="bagactivation-input-tag"
                      label="1st Bag Description"
                      variant="outlined"
                      name="bag_description1"
                      onChange={handelChange}
                      value={formdata.bag_description1}
                      //onChange={this.changeHandler}
                    />
                  </div>
                </div>

                {formdata.no_of_bags === 2 && (
                  <>
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          fullWidth
                          type="text"
                          // className="bagactivation-input-tag"
                          label="2nd Bag Brand"
                          variant="outlined"
                          name="bag_brand2"
                          onChange={handelChange}
                          value={formdata.bag_brand2}
                          //onChange={this.changeHandler}
                        />
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          fullWidth
                          type="text"
                          // className="bagactivation-input-tag"
                          label="2nd Bag Description"
                          variant="outlined"
                          name="bag_description2"
                          onChange={handelChange}
                          value={formdata.bag_description2}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="editPetDetails-btn-row">
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ width: "100%" }}
                    className="submit-login bg-warning text-white mt-2"
                  >
                    submit
                    {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                  </Button>

                  <Button
                    type="button"
                    variant="contained"
                    style={{ width: "100%" }}
                    className="bg-white mt-2 cancel-btn submit-login "
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </form>

              {showLoader && (
                <div className="loader-container">
                  <div className="loader">
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                      }
                      alt="Loading..."
                      loading="lazy"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </div>
              )}

              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                //open={this.state.snackbaropen}
                autoHideDuration={8000}
                //onClose={this.snackbarClose}
                //message={this.state.snackbarmsg}
                action={[
                  <IconButton
                    arial-label="Close"
                    color="inherit"
                    //onClick={this.snackbarClose}
                  >
                    X
                  </IconButton>,
                ]}
              />
            </div>
          </div>
        </div>
        
      </div>
      <Dialog open={succesmodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div style={{ margin: "1rem" }}>
            <>
              <p style={{ textAlign: "center" }}>
              Would you like to activate a new tag?
              </p>
            </>
          </div>

          <div className="crop-actionsSub">
            <Button
              onClick={() => {
                setsuccesmodal(false);
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
            >
              Cancel
            </Button>

            <Button onClick={handelactivate}>Yes</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={activatemodal}
        fullWidth
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <h2 className="dashboard-activateTag-popup">Tag Activation</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
              style={{ width: "30px", height: "30px" }}
              alt="QR Code"
              onClick={() => setTagscaner(true)}
            />

            <input
              className="dashboard-input__field__dialogs"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              value={activatetagnumber}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();

                setactivatetagnumber(inputValue);
              }}
              placeholder="Owner ID"
            />
          </div>

          <div
            style={{
              padding: "1em",
              marginBottom: "1px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <button
              className="dashboard-verifyOtp-button"
              onClick={activeredirection}
            >
              ACTIVATE
            </button>
            <Button onClick={() => setactivatemodal(false)}>Cancel</Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={tagScaner}
        fullWidth
        maxWidth="xs"
        onClose={() => setTagscaner(false)}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={(result, error) => {
            if (result) {
              setactivatetagnumber(
                result?.text
                  .split("/")
                  [result?.text.split("/").length - 1].toUpperCase()
              );
              setTagscaner(false);
            }
            if (error) {
              console.error(error.message);
            }
          }}
          style={{ width: "100%" }}
        />
      </Dialog>
      <Dialog open={imageStates.imagemodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ margin: "1rem" }}>
              {!imageStates.uploaderror ? (
                <>
                  <ReactCrop
                    keepSelection
                    crop={cropState.crop}
                    aspect={1}
                    minWidth={100}
                    onChange={(percentCrop) => {
                      setCropstate((prev) => {
                        return {
                          ...prev,
                          crop: percentCrop,
                        };
                      });
                    }}
                  >
                    <img
                      ref={imageRef}
                      alt="crop"
                      width="100%"
                      height="100%"
                      src={imageStates.imageUrl}
                      onLoad={cropSetup}
                    ></img>
                  </ReactCrop>
                </>
              ) : (
                <>
                  <p style={{ color: "red", textAlign: "center" }}>
                    {imageStates.message}
                  </p>
                </>
              )}
            </div>

            <div className="crop-actionsSub">
              <Button onClick={handelcancel}>Cancel</Button>
              {!imageStates.uploaderror ? (
                <Button onClick={handelimagecrop}>Submit</Button>
              ) : null}
            </div>
          </form>
        </DialogContent>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </Dialog>

      <Snackbar
        open={snacbarstate.open}
        autoHideDuration={3000}
        message={snacbarstate.message}
        action={[
          <IconButton
            key="close"
            arial-label="Close"
            color="inherit"
            onClick={() => {
              setsnackbarstate((prev) => {
                return {
                  ...prev,
                  open: false,
                };
              });
            }}
          >
            X
          </IconButton>,
        ]}
      />
    </>
  );
}

export default Digitalbagcard;
